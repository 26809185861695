import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";

function AboutWrapperArea() {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <div className="about-main-wrappper pt-110">
        <div className="container">
          <div className="about-tab-wrapper">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="about-tab-image-grid text-center">
                  <div className="about-video d-inline-block">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/EU5GEOZaeao?si=Ip_l_SjJqmsXkIhG&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
         
                </div>
              </div>
              <div className="col-lg-6 mt-5 mt-lg-0">
                <div className="about-tab-wrap">
                  <h2 className="about-wrap-title">
                  The A La Carte <span>Travel Services</span> in Thailand.
                  </h2>
                  <div className="about-tab-switcher">
                    <ul
                      className="nav nav-pills mb-3 justify-content-md-between justify-content-center"
                      id="about-tab-pills"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <div
                          className="nav-link active"
                          id="pills-about1"
                          data-bs-toggle="pill"
                          data-bs-target="#about-pills1"
                          role="tab"
                          aria-controls="about-pills1"
                          aria-selected="true"
                        >
                          <h3>10+</h3>
                          <h6>Years Experience</h6>
                        </div>
                      </li>
                      <li className="nav-item" role="presentation">
                        <div
                          className="nav-link"
                          id="pills-about2"
                          data-bs-toggle="pill"
                          data-bs-target="#about-pills2"
                          role="tab"
                          aria-controls="about-pills2"
                          aria-selected="false"
                        >
                          <h3>20+</h3>
                          <h6>Tour Guide</h6>
                        </div>
                      </li>
                      <li className="nav-item" role="presentation">
                        <div
                          className="nav-link"
                          id="pills-about3"
                          data-bs-toggle="pill"
                          data-bs-target="#about-pills3"
                          role="tab"
                          aria-controls="about-pills3"
                          aria-selected="false"
                        >
                          <h3>100+</h3>
                          <h6>Travelar Connect</h6>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="tab-content about-tab-content"
                    id="pills-tabContent"
                  >
                    <div
                      className="tab-pane fade show active"
                      id="about-pills1"
                      role="tabpanel"
                      aria-labelledby="pills-about1"
                    >
                      <p>
                      Juukkoo was born in 1985. We are proud to have taken care of travelers for decades. As our experience, we gather points
                    for people and corporate partners who are passionate about tourism to come together to create a network of services.
                  </p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="about-pills2"
                      role="tabpanel"
                      aria-labelledby="pills-about2"
                    >
                      <p>
                      For this reason we have a wide variety of products such as Tickets, Tour Packages, Hotels, Transfers. Every item of
                    Juukkoo has been qualified. We strictly monitor the quality of service provided by our business partners as well as our
                    own employees in order to create a good memory for all travelers.
                      </p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="about-pills3"
                      role="tabpanel"
                      aria-labelledby="pills-about3"
                    >
                      <p>
                      As a result, we have many loyal customers who return to Thailand again and again. Because of the charm of our locations,
                    foods, travels and services.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-110">
            <div className="col-lg-6">
              <div className="achievement-counter-wrap">
                <h2 className="about-wrap-title">
                Why you are travel with <span>Juukkoo?</span>
                </h2>
                <div className="achievement-counter-cards">
                  <div className="achievement-counter-card flex-sm-row flex-column text-sm-start text-center ">
                
                    <p>
                    Qualified A La Carte Travel Products
                    </p>
                  </div>
                  <div className="achievement-counter-card flex-sm-row flex-column text-sm-start text-center">
                
                    <p>
                    We offer the most reasonable price throughout market.
                    </p>
                  </div>
                  <div className="achievement-counter-card flex-sm-row flex-column text-sm-start text-center">
                  
                    <p>
                    We have staff to assist in all stages of your holiday
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-image-group mt-5 mt-lg-0">
               
              <img width={"600px"} src={process.env.PUBLIC_URL + "/images/about/about-g2.jpg"} alt="aboutIMG" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <React.Fragment>
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId="3SsK-cxlj_w"
          onClose={() => setOpen(false)}
        />
      </React.Fragment>
    </>
  );
}

export default AboutWrapperArea;
