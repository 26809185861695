import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';

function TransferForm(props) {

    const history = useHistory();
    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            remark: "",
        },
    });

    const [startDate, setStartDate] = useState(tomorrow);
    const [qty, setQty] = useState(1);
     const [price, setPrice] = useState(props.transfer.price);
    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

  useEffect(() => {
   setPrice(props.transfer.price*qty)
  }, [qty]);

      const onSubmit = (data) =>  {
          let order = {
              order_date: startDate,
              order_items: [
                  {
                      "item_id": props.transfer.id,
                      "item_name": props.transfer.origin.name+" to "+props.transfer.destination.name,
                      "qty": qty,
                      "price": props.transfer.price,
                      "item_type": "transfer"
                  },
              ],
              "product_id": props.transfer.id,
              "product_name": props.transfer.origin.name+" to "+props.transfer.destination.name,
              "product_highlight": props.transfer.description,
              "product_type": "transfer",
              "remark": data.remark,
              "total": props.transfer.price*qty
          }

          scrollTop();
          history.push('/check-out', { order });
      }

    return (
        <>
            <aside className="package-widget-style-2 widget-form">
                <div className="widget-body">
                    <form onSubmit={handleSubmit(onSubmit)} id="booking-form">
                        <div className="booking-form-wrapper">
                            <div className="custom-input-group">
                                <DatePicker
                                    closeOnScroll={(e) => e.target === document}
                                    selected={startDate}
                                    showTimeSelect
                                    minDate={tomorrow}
                                    onChange={(date) => setStartDate(date)}
                                    dateFormat="Pp"
                                />
                            </div>

                                    <div className="row mt-3">
                                             <div className="col-sm-3">
                                           Qty
                                               </div>
                                               <div className="col-sm-9">
                                 <input name="qty" type="number" min="1" value={qty} onChange={(q) => setQty(q.target.value)} />
                                 </div>
                                      </div>
                            <div className="col-sm-12 fs-5 mt-3">
                                Total: <b>{props.transfer.price*qty}</b>
                            </div>

                            <div className="custom-input-group">
                                <div className="submite-btn">
                                    <button type="submit">Book Now</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </aside>
        </>
    );
}

export default TransferForm;
