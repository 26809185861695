import React from "react";
import { Link } from "react-router-dom";

function TermsWrap() {
  return (
    <>
      <div className="blog-details-wrapper pt-110">
        <div className="container">
          <div className="row">
          <div className="col-lg-12">
        <div className="blog-details">
          <div className="post-header">
            <h2 className="post-title">
            Terms and Conditions
            </h2>
          </div>
          <div className="post-header">How to book with us?</div>
          <div className="post-body">
            <p>
                Conditions and procedures for receiving services. You can make a reservation with us in 2 ways. One contact our staff at
                  info@juukkoo.com, we provide personalized travel services, small group Large groups, corporate groups, or modify the list
                  of services according to your needs. Two cases of booking a service through our website Start by selecting the product
                  list you want, choosing the province, choosing the date you want to use the service, and choosing the number of people,
                  respectively. You may use filtering tools when needed. Please study the complete list of products and services that you
                  want. If you press the Confirm button, you agree to the terms and conditions of the service as shown on the website. Next,
                  we will confirm the booking for you. Please study the conditions of making a reservation for more details.
            </p>
            <div className="blog-quote-box-one d-flex flex-column flex-sm-row">
              <div className="quote-icon">
                <img
                  src={process.env.PUBLIC_URL + "/images/icons/blogquote.svg"}
                  alt="blogDetailsIMG"
                />
              </div>
              <blockquote>
                <p>
                The booking will be complete once
                  you have made the payment.
                </p>
              </blockquote>
            </div>
            <h3 className="sub-title">Booking and Reservation conditions</h3>
            <p className="mt-1">
            When you decide to book any service on this website you are deemed to have accepted the terms and conditions of the
                  service booking as appearing on our website. Please complete your personal information, especially the information marked
                  with an asterisk. We will process reservations for goods and services with partner companies for you on your behalf. If
                  some important information is missing, the system may reject the booking you selected. We will notify you of the booking
                  status through the website while you are using our website along with sending an email to you. You can also find more
                  information on the Help Center page on our website via the CHECK BOOKING STATUS menu. The booking will be complete once
                  you have made the payment.
            </p>
            <div className="blog-quote-box-two text-center">
              <div className="quote-icon">
                <img
                  src={process.env.PUBLIC_URL + "/images/icons/blogquote.svg"}
                  alt="blogDetailsIMG"
                />
              </div>
              <blockquote>
                <p>
                Products and services on this website accept
                  payments in Thai baht.
                </p>
              </blockquote>
            </div>
            <h3 className="sub-title">Payment Terms / Payment Method</h3>
            <p className="mt-1">
            Please select a payment method that is convenient for you. You can pay via credit card. Please pay for the goods and
                  services you choose within 24 hours or pay before the due date that you use the service, whichever comes first. Otherwise,
                  your booking will be automatically canceled. In the event that you have made the payment, you are deemed to have accepted
                  the terms and conditions of the service booking as displayed on our website. Products and services on this website accept
                  payments in Thai baht. You should study the exchange rate in your currency.
            </p>
            <h3 className="sub-title">Reservation Confirmation Conditions</h3>
            <p className="mt-1">
                  When you complete the payment through the system on the website In the event that you are using the website, we will
                  inform you of the booking status via your device screen. Along with sending an e-mail to you, please check your inbox or
                  trash box in your e-mail. You can also find more information on the Help Center page on our website via the CHECK BOOKING
                  STATUS menu. Please note that the booking will be complete once you have received all of these documents by email: billing
                  invoice, confirmation, and tax invoice/receipt.
                </p>
                <p>
                  Terms of use of the voucher and the conditions of service that you choose When the schedule that you reserve the service
                  Please show the confirmation slip to the staff at each registration point to confirm your benefits.
                </p>
                <div className="blog-quote-box-one d-flex flex-column flex-sm-row">
              <div className="quote-icon">
                <img
                  src={process.env.PUBLIC_URL + "/images/icons/blogquote.svg"}
                  alt="blogDetailsIMG"
                />
              </div>
              <blockquote>
                <p>
                Please contact our staff via email.
                  info@juukkoo.com only For goods and services that can cancel reservations according to the rights document Please notify
                  the cancellation via email in advance before the scheduled service.
                </p>
              </blockquote>
            </div>
            <h3 className="sub-title">Booking Cancellation Conditions</h3>
            <p className="mt-1">
            Hotel / Transfer / Entrance Ticket / Package <br />
                  For your convenience and speed of receiving your services, please prepare these documents as a reference for your
                  benefits, billing, confirmation, and tax invoices/receipts. Please check the terms and conditions of your booking in
                  detail. Because there may be goods and services that cannot be modified, modified, or canceled. In case you want to cancel
                  the reservation, we need to take the time to review the information in detail. Please contact our staff via email.
                  info@juukkoo.com only For goods and services that can cancel reservations according to the rights document Please notify
                  the cancellation via email in advance before the scheduled service. and must comply with the rules that appear in the
                  booking confirmation or confirmation Otherwise, your booking will automatically enter the conditions in case the customer
                  refuses to use the No Show service.
            </p>
            <h3 className="sub-title">No Show</h3>
            <p className="mt-1">
            In the event that it is due to use the service that you have reserved and you do not confirm your benefits at the
                  registration point where you reserve the service, you will be deemed to have forfeited your benefits and your booking will
                  automatically be subject to the customer refusal of the No Show service. We and our website reserve the right not to
                  refund you any refunds already received.
            </p>
            <h3 className="sub-title">Conditions in case of changing the service date</h3>
            <p className="mt-1">
            For your convenience and speed of receiving your services, please prepare these documents as a reference for your
                  benefits, billing, confirmation, tax invoices/receipts. Please check the terms and conditions of your booking. Because
                  there may be products and services that cannot be changed on the service date. Before the scheduled date of your booking,
                  you can contact our staff at info@juukkoo.com In case of past the scheduled service that you have already reserved You
                  will be deemed to have waived your benefits and your booking will automatically be subject to the customer refusal to use
                  the No Show service.
            </p>
            <h3 className="sub-title">Other</h3>
            <p className="mt-1">
            This website is not intended for minors under 18, should be accompanied by an adult. We reserve the right to modify the
                  terms and conditions of products and services without prior notice.
            </p>
          </div>
        </div>
      </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsWrap;
