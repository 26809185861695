import React from "react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import moment from 'moment';

import { useAlert } from 'react-alert'

function ChekoutWrap(props) {

  const alert = useAlert();
  const { order } = props.location.state;
  const CREATE_ORDER = gql`
    mutation CreateOrder($input: CreateOrder!) {
      createOrder(input: $input) {
        ok
        message
        data {
          id
          order_no
          created_at
          order_date
          product_type
          product_id
          product_name
          remark
          status
          grand_total
          order_items {
            id
            item_type
            item_id
            item_name
            qty
            sub_total
          }
          customers {
            id
            first_name
            last_name
            email
            nationality
            passport
            mobile
          }
        }
        otp_url
      }
    }
  `;

  const [createOrder, { data, loading, error }] = useMutation(CREATE_ORDER,
    {
      onCompleted: (data) => {
        if(data.createOrder.ok){
          window.location.replace(data.createOrder.otp_url);
        }else{
        alert.show(data.createOrder.message)
        }
      },
      onError: (error) => {
        alert.show(error)
      },
    });
  const order_date = moment(order.order_date).format("DD MMMM YYYY");
  if (loading) return "Submitting...";
  if (error) return `Submission error! ${error.message}`;

  return (
    <>
      <div className="checkout">
        <section className="py-5">
          <div className="container px-4 px-lg-5 my-5">
            <div className="row">
              <div className="col-md-4 order-md-2 mb-4">
                <h4 className="d-flex justify-content-between align-items-center mb-3">
                  <span className="text-muted">Your cart</span>
                  <span className="badge badge-secondary badge-pill">{order.order_items.length}</span>
                </h4>
                <ul className="list-group mb-3">
                  <li className="list-group-item d-flex justify-content-between lh-condensed">
                    <div>
                      <h6 className="my-0">{order.product_name}</h6>
                      <small className="text-muted">{order_date}</small>
                    </div>
                  </li>
                  {order.order_items.map(item => (
                  <li className="list-group-item d-flex justify-content-between lh-condensed">
                    <div>
                      <h6 className="my-0">{item.item_type}</h6>
                      <small className="text-muted">x {item.qty}</small>
                    </div>
                    <span className="text-muted">฿ {((item.price-((item.price/1.07)*0.07))*item.qty).toFixed(2)}</span>
                  </li>
                  ))}
                         <li className="list-group-item d-flex justify-content-between bg-light">
                    <div className="text-success">
                      <h6 className="my-0">Sub total</h6>
                      <small></small>
                    </div>
                    <span className="text-success">{
                        (order.total-((order.total/1.07)*0.07)).toFixed(2)
                    }</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between bg-light">
                    <div className="text-success">
                      <h6 className="my-0">VAT</h6>
                      <small>7%</small>
                    </div>
                    <span className="text-success">{
                        ((order.total/1.07)*0.07).toFixed(2)
                    }</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <span>Total (THB)</span>
                    <strong>฿ {order.total.toFixed(2)}</strong>
                  </li>
                </ul>
              </div>
              <div className="col-md-8 order-md-1">
                <h4 className="mb-3">Contact Informations</h4>
                <Formik
       initialValues={{condition: false, passport: '', email: '', firstname: '', lastname: '', phone: '', nationality: '', additional_request: '', meeting_point: '', language: 'EN' }}
       validate={values => {
         const errors = {};
         if (!values.email) {
           errors.email = 'Required';
         } else if (
           !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
         ) {
           errors.email = 'Invalid email address';
         }
         if (!values.passport) {
          errors.passport = 'Required';
        }
         if (!values.firstname) {
          errors.firstname = 'Required';
        }
        if (!values.lastname) {
          errors.lastname = 'Required';
        }
        if (!values.phone) {
          errors.phone = 'Required';
        }
        if (!values.condition) {
          errors.condition = 'Required';
        }
        if (!values.nationality) {
          errors.nationality = 'Required';
        }
        if (!values.meeting_point) {
          errors.meeting_point = 'Required';
        }
         return errors;
       }}
       onSubmit={(values, { setSubmitting }) => {
    
        const customer = {
        first_name: values.firstname,
        last_name: values.lastname,
        email: values.email,
        mobile:values.phone,
        nationality: values.nationality,
        passport: values.passport,
        }

        const item = [];

        order.order_items.forEach(element => {
          item.push(
            {
              item_id: element.item_id,
              item_name: element.item_name,
              item_type: element.item_type,
              qty: element.qty,
            }
          )
        });

        const order_request = {
          "customers": [
            customer
          ],
          "language": values.language,
          "meeting_point": values.meeting_point,
          "order_date": order.order_date,
          "order_items": item,
          "product_id": order.product_id,
          "product_name": order.product_name,
          "product_type": order.product_type,
          "remark": values.additional_request
        }

        createOrder({
          variables: {
            input: order_request,
          },
        });
        }}
     >
       {({
         values,
         errors,
         touched,
         handleChange,
         handleBlur,
         handleSubmit,
         isSubmitting,
         /* and other goodies */
       }) => (
                <form className="needs-validation" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="firstname" className="form-label">
                        First name
                      </label>
                      <Field type="text" 
                        className="form-control" name="firstname" />
                    <ErrorMessage name="firstname" component="div">
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="lastname" className="form-label">
                        Last name
                      </label>
                      <Field type="text" 
                        className="form-control" name="lastname" />
                    <ErrorMessage name="lastname" component="div" >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <Field type="email" 
                      value={values.email}
                        className="form-control" name="email" />
                    <ErrorMessage name="email" component="div">
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="phone" className="form-label">
                        Phone
                      </label>
                      <Field type="text" 
                        className="form-control" name="phone" />
                    <ErrorMessage name="phone" component="div">
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                    </div>
                    <div className="col-md-3 mb-3">
                        <label htmlFor="nationality" className="form-label">
                          Nationality
                        </label>
                        <Field
                         className="form-control" 
             component="select"
             id="nationality"
             name="nationality"
             multiple={false}
             onChange={handleChange}
             onBlur={handleBlur}
           >
  <option value="">-- select one --</option>
  <option value="afghan">Afghan</option>
  <option value="albanian">Albanian</option>
  <option value="algerian">Algerian</option>
  <option value="american">American</option>
  <option value="andorran">Andorran</option>
  <option value="angolan">Angolan</option>
  <option value="antiguans">Antiguans</option>
  <option value="argentinean">Argentinean</option>
  <option value="armenian">Armenian</option>
  <option value="australian">Australian</option>
  <option value="austrian">Austrian</option>
  <option value="azerbaijani">Azerbaijani</option>
  <option value="bahamian">Bahamian</option>
  <option value="bahraini">Bahraini</option>
  <option value="bangladeshi">Bangladeshi</option>
  <option value="barbadian">Barbadian</option>
  <option value="barbudans">Barbudans</option>
  <option value="batswana">Batswana</option>
  <option value="belarusian">Belarusian</option>
  <option value="belgian">Belgian</option>
  <option value="belizean">Belizean</option>
  <option value="beninese">Beninese</option>
  <option value="bhutanese">Bhutanese</option>
  <option value="bolivian">Bolivian</option>
  <option value="bosnian">Bosnian</option>
  <option value="brazilian">Brazilian</option>
  <option value="british">British</option>
  <option value="bruneian">Bruneian</option>
  <option value="bulgarian">Bulgarian</option>
  <option value="burkinabe">Burkinabe</option>
  <option value="burmese">Burmese</option>
  <option value="burundian">Burundian</option>
  <option value="cambodian">Cambodian</option>
  <option value="cameroonian">Cameroonian</option>
  <option value="canadian">Canadian</option>
  <option value="cape verdean">Cape Verdean</option>
  <option value="central african">Central African</option>
  <option value="chadian">Chadian</option>
  <option value="chilean">Chilean</option>
  <option value="chinese">Chinese</option>
  <option value="colombian">Colombian</option>
  <option value="comoran">Comoran</option>
  <option value="congolese">Congolese</option>
  <option value="costa rican">Costa Rican</option>
  <option value="croatian">Croatian</option>
  <option value="cuban">Cuban</option>
  <option value="cypriot">Cypriot</option>
  <option value="czech">Czech</option>
  <option value="danish">Danish</option>
  <option value="djibouti">Djibouti</option>
  <option value="dominican">Dominican</option>
  <option value="dutch">Dutch</option>
  <option value="east timorese">East Timorese</option>
  <option value="ecuadorean">Ecuadorean</option>
  <option value="egyptian">Egyptian</option>
  <option value="emirian">Emirian</option>
  <option value="equatorial guinean">Equatorial Guinean</option>
  <option value="eritrean">Eritrean</option>
  <option value="estonian">Estonian</option>
  <option value="ethiopian">Ethiopian</option>
  <option value="fijian">Fijian</option>
  <option value="filipino">Filipino</option>
  <option value="finnish">Finnish</option>
  <option value="french">French</option>
  <option value="gabonese">Gabonese</option>
  <option value="gambian">Gambian</option>
  <option value="georgian">Georgian</option>
  <option value="german">German</option>
  <option value="ghanaian">Ghanaian</option>
  <option value="greek">Greek</option>
  <option value="grenadian">Grenadian</option>
  <option value="guatemalan">Guatemalan</option>
  <option value="guinea-bissauan">Guinea-Bissauan</option>
  <option value="guinean">Guinean</option>
  <option value="guyanese">Guyanese</option>
  <option value="haitian">Haitian</option>
  <option value="herzegovinian">Herzegovinian</option>
  <option value="honduran">Honduran</option>
  <option value="hungarian">Hungarian</option>
  <option value="icelander">Icelander</option>
  <option value="indian">Indian</option>
  <option value="indonesian">Indonesian</option>
  <option value="iranian">Iranian</option>
  <option value="iraqi">Iraqi</option>
  <option value="irish">Irish</option>
  <option value="israeli">Israeli</option>
  <option value="italian">Italian</option>
  <option value="ivorian">Ivorian</option>
  <option value="jamaican">Jamaican</option>
  <option value="japanese">Japanese</option>
  <option value="jordanian">Jordanian</option>
  <option value="kazakhstani">Kazakhstani</option>
  <option value="kenyan">Kenyan</option>
  <option value="kittian and nevisian">Kittian and Nevisian</option>
  <option value="kuwaiti">Kuwaiti</option>
  <option value="kyrgyz">Kyrgyz</option>
  <option value="laotian">Laotian</option>
  <option value="latvian">Latvian</option>
  <option value="lebanese">Lebanese</option>
  <option value="liberian">Liberian</option>
  <option value="libyan">Libyan</option>
  <option value="liechtensteiner">Liechtensteiner</option>
  <option value="lithuanian">Lithuanian</option>
  <option value="luxembourger">Luxembourger</option>
  <option value="macedonian">Macedonian</option>
  <option value="malagasy">Malagasy</option>
  <option value="malawian">Malawian</option>
  <option value="malaysian">Malaysian</option>
  <option value="maldivan">Maldivan</option>
  <option value="malian">Malian</option>
  <option value="maltese">Maltese</option>
  <option value="marshallese">Marshallese</option>
  <option value="mauritanian">Mauritanian</option>
  <option value="mauritian">Mauritian</option>
  <option value="mexican">Mexican</option>
  <option value="micronesian">Micronesian</option>
  <option value="moldovan">Moldovan</option>
  <option value="monacan">Monacan</option>
  <option value="mongolian">Mongolian</option>
  <option value="moroccan">Moroccan</option>
  <option value="mosotho">Mosotho</option>
  <option value="motswana">Motswana</option>
  <option value="mozambican">Mozambican</option>
  <option value="namibian">Namibian</option>
  <option value="nauruan">Nauruan</option>
  <option value="nepalese">Nepalese</option>
  <option value="new zealander">New Zealander</option>
  <option value="ni-vanuatu">Ni-Vanuatu</option>
  <option value="nicaraguan">Nicaraguan</option>
  <option value="nigerien">Nigerien</option>
  <option value="north korean">North Korean</option>
  <option value="northern irish">Northern Irish</option>
  <option value="norwegian">Norwegian</option>
  <option value="omani">Omani</option>
  <option value="pakistani">Pakistani</option>
  <option value="palauan">Palauan</option>
  <option value="panamanian">Panamanian</option>
  <option value="papua new guinean">Papua New Guinean</option>
  <option value="paraguayan">Paraguayan</option>
  <option value="peruvian">Peruvian</option>
  <option value="polish">Polish</option>
  <option value="portuguese">Portuguese</option>
  <option value="qatari">Qatari</option>
  <option value="romanian">Romanian</option>
  <option value="russian">Russian</option>
  <option value="rwandan">Rwandan</option>
  <option value="saint lucian">Saint Lucian</option>
  <option value="salvadoran">Salvadoran</option>
  <option value="samoan">Samoan</option>
  <option value="san marinese">San Marinese</option>
  <option value="sao tomean">Sao Tomean</option>
  <option value="saudi">Saudi</option>
  <option value="scottish">Scottish</option>
  <option value="senegalese">Senegalese</option>
  <option value="serbian">Serbian</option>
  <option value="seychellois">Seychellois</option>
  <option value="sierra leonean">Sierra Leonean</option>
  <option value="singaporean">Singaporean</option>
  <option value="slovakian">Slovakian</option>
  <option value="slovenian">Slovenian</option>
  <option value="solomon islander">Solomon Islander</option>
  <option value="somali">Somali</option>
  <option value="south african">South African</option>
  <option value="south korean">South Korean</option>
  <option value="spanish">Spanish</option>
  <option value="sri lankan">Sri Lankan</option>
  <option value="sudanese">Sudanese</option>
  <option value="surinamer">Surinamer</option>
  <option value="swazi">Swazi</option>
  <option value="swedish">Swedish</option>
  <option value="swiss">Swiss</option>
  <option value="syrian">Syrian</option>
  <option value="taiwanese">Taiwanese</option>
  <option value="tajik">Tajik</option>
  <option value="tanzanian">Tanzanian</option>
  <option value="thai">Thai</option>
  <option value="togolese">Togolese</option>
  <option value="tongan">Tongan</option>
  <option value="trinidadian or tobagonian">Trinidadian or Tobagonian</option>
  <option value="tunisian">Tunisian</option>
  <option value="turkish">Turkish</option>
  <option value="tuvaluan">Tuvaluan</option>
  <option value="ugandan">Ugandan</option>
  <option value="ukrainian">Ukrainian</option>
  <option value="uruguayan">Uruguayan</option>
  <option value="uzbekistani">Uzbekistani</option>
  <option value="venezuelan">Venezuelan</option>
  <option value="vietnamese">Vietnamese</option>
  <option value="welsh">Welsh</option>
  <option value="yemenite">Yemenite</option>
  <option value="zambian">Zambian</option>
  <option value="zimbabwean">Zimbabwean</option>
           </Field>
           <ErrorMessage name="nationality" component="div">
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                    </div>
                    </div>
                  <div className="row">
                  <div className="col-md-4 mb-3">
                      <label htmlFor="passport" className="form-label">
                        Passport No.
                      </label>
                      <Field type="text" 
                        className="form-control" name="passport" />
                    <ErrorMessage name="passport" component="div">
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                    </div>
                    <div className="col-md-5 mb-3">
                      <label htmlFor="meeting_point" className="form-label">
                        Meeting Point <span className="text-muted">(Where do you stay)</span>
                      </label>
                      <Field type="text" 
                        className="form-control" name="meeting_point" />
                    <ErrorMessage name="meeting_point" component="div">
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                    </div>
                    <div className="col-md-3 mb-3">
                    {
                order.product_type === "package" ?
                <>
                        <label htmlFor="language" className="form-label">
                          Preferred guide's language
                        </label>
                        <Field
                         className="form-control" 
             component="select"
             id="language"
             name="language"
             multiple={false}
             onChange={handleChange}
             onBlur={handleBlur}
           >
           <option value="">Select</option>
               <option value="CN">Chinese</option>
               <option value="EN">English</option>
           </Field>
           <ErrorMessage name="language" component="div">
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage> </> : "" }
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="additional_request" className="form-label">
                        Additional request <span className="text-muted">(Optional)</span>
                      </label>
                      <Field type="text" 
                        className="form-control" name="additional_request" />
                    </div>
                  </div>
                  <hr className="mb-4" />
                  <div className="form-check">
                     <Field type="checkbox" className="form-check-input" name="condition" />
                    <label className="form-check-label ms-2" htmlFor="condition">
                    By clicking here, I state that I have read and understood the terms and conditions.
                    </label>
                  </div>
                  <hr className="mb-4" />
                  <button className="btn btn-primary px-4 rounded-pill pull-right" type="submit" disabled={isSubmitting}>
                    Place Order
                  </button>
                </form>
                  )}
                  </Formik>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default ChekoutWrap;
