import React from "react";


function PackageHeader(props) {
  return (
    <>
      <div className="pd-header">
        <div className=" pd-top row row-cols-lg-4 row-cols-md-2 row-cols-2 gy-4">
          <div className="col">
            <div className="pd-single-info">
              <div className="info-icon">
                <img src={process.env.PUBLIC_URL + "/images/icons/pd1.svg"}  alt="Duration" />
              </div>
              <div className="info">
                <h6>Duration</h6>
                <span>{props.tour.duration}</span>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="pd-single-info">
              <div className="info-icon">
                <img src={process.env.PUBLIC_URL + "/images/icons/pd2.svg"} alt="Tour Type" />
              </div>
              <div className="info">
                <h6>Tour Type</h6>
                <span>{props.tour.tourType === 0 ? "Half Day" : props.tour.tourType === 1 ? "Full Day" : "Over Night(s)"}</span>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="pd-single-info">
              <div className="info-icon">
                <img src={process.env.PUBLIC_URL + "/images/icons/pd3.svg"} alt="Group Size" />
              </div>
              <div className="info">
                <h6>Group Size</h6>
                <span>{props.tour.minPeople}-{props.tour.maxPeople}</span>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="pd-single-info">
              <div className="info-icon">
                <img src={process.env.PUBLIC_URL + "/images/icons/pd4.svg"} alt="High Season" />
              </div>
              <div className="info">
                <h6>High Season</h6>
                <span>{props.tour.highSeason}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="pd-thumb">
          <img src={props.tour.featuredImage} alt="Private Tour" />
        </div>
        <div className="header-bottom">
          <div className="pd-lavel d-flex justify-content-between align-items-center flex-wrap gap-2">
          {props.tour.locations.length > 0 &&
            <h5 className="location">
              <i className="bi bi-geo-alt" /> {props.tour.locations.map((location) => (<span className="me-2">{location.location}</span>))}
            </h5>
          }
            <ul className="d-flex align-items-center rating">
              <li>
                <i className={`bi ${props.tour.rating > 0 ? "bi-star-fill" : "bi-star"}`} />
              </li>
              <li>
              <i className={`bi ${props.tour.rating > 1 ? "bi-star-fill" : "bi-star"}`} />
              </li>
              <li>
                <i className={`bi ${props.tour.rating > 2 ? "bi-star-fill" : "bi-star"}`} />
              </li>
              <li>
                <i className={`bi ${props.tour.rating > 3 ? "bi-star-fill" : "bi-star"}`} />
              </li>
              <li>
                <i className={`bi ${props.tour.rating > 4 ? "bi-star-fill" : "bi-star"}`} />
              </li>
            </ul>
          </div>
          <h2 className="pd-title">{props.tour.title}</h2>
        </div>
      </div>
    </>
  );
}

export default PackageHeader;
