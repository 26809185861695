import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import FaqWrapper from "./FaqWrapper";

function FaqPage() {
  return (
    <>
      <Breadcrumb name="FAQ" />
      <FaqWrapper />
    </>
  );
}

export default FaqPage;
