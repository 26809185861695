import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from 'query-string'
import Pagination from "../../common/Pagination";
import PackageCard from "./PackageCard";
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import Select from "react-select";

function PackageGridWrapper() {

    const GET_TOUR = gql`
    query ActiveTours($limit: Int, $offset: Int, $isFeatured: Boolean) {
      activeTours(limit: $limit, offset: $offset, isFeatured: $isFeatured) {
        edges {
          id
          title
          highlight
          content
          tourType
          price
          salePrice
          highSeason
          checkIn
          checkOut
          duration
          days
          nights
          bookingPeriod
          minPeople
          maxPeople
          isActive
          isFeatured
          featuredImage
          rating
          locations {
            id
            location
          }
        }
        message
        ok
        totalCount
      }
    }
  `;
  const [selectedOption, setSelectedOption] = useState({ value: null, label: "All" });
  const [provinceOption, setprovinceOptions] = useState({ value: 0, label: "All" });
  const [getTours, ToursRes] = useLazyQuery(GET_TOUR);
  const [page, setPage] = useState(1);

  const [fiteredTours, setFiteredTours] = useState([]);
  const [tours, setTours] = useState([]);
  let offset = 0;
  let limit = 60;
    let minHeight = "650px";
  const { search } = useLocation()

  const SetData = (value) => {
    let allData = value.activeTours.edges;
    setTours(allData)
  }

  useEffect(() => {
  }, []);
 
  useEffect(() => {
    let values = queryString.parse(search)
    let page_no = values.page ? values.page : 1;
    setPage(page_no)
  }, [search]);

const { loading, error, data } = useQuery(GET_TOUR, {
  variables: { 
    "limit": limit,
    "offset": page === 1? 0: (page-1)*limit,
    "isFeatured": null,
}, onCompleted: SetData
});

useEffect(() => {
  filterByProvince()
}, [selectedOption]);

useEffect(() => {
filterByProvince()
}, [provinceOption]);

const filterByProvince = () => {
    let toursData = tours;
       let filtered = [];
    for (const tour of toursData) {

     if(provinceOption != null && provinceOption.value != 0){
    for (const location of tour.locations) {
      if (location.location === provinceOption.label) {
        filtered.push(tour)
        break;
      }
    }
}else{
             filtered.push(tour)
           }
    }

             if(selectedOption.value != null && selectedOption.value != 0){
           const filteredOption = filtered.filter(tour => tour.tourType === selectedOption.value-1);
           filtered = filteredOption
               }

         setFiteredTours(filtered)
    }

if (loading) return 'Loading...';
if (error) return `Error! ${error.message}`;
if (ToursRes.loading) return 'Loading...';
if (ToursRes.error) return `Error! ${ToursRes.error.message}`;

const packageOptions = [
  { value: 0, label: "All" },
  { value: 1, label: "Half Day" },
  { value: 2, label: "Full Day" },
];

const provinceOptions = [
  { value: 0, label: "All" },
  { value: 1, label: "Bangkok" },
  { value: 2, label: "Pattaya" },
  { value: 3, label: "Ayutthaya" },
  { value: 4, label: "Ratchaburi" },
  { value: 5, label: "Nakornpathom" },
  { value: 6, label: "Hua Hin" },
  { value: 7, label: "Chonburi" },
  { value: 8, label: "Damnernsaduak" },
];


  return (
    <>
      {/* ===============  Package gird area start =============== */}
      <div className="package-wrapper pt-110">
        <div className="container mw-100" style={{minHeight: minHeight}}>
        <div className="row g-4 mb-2">
                    <div className="col-lg-10">
                        <div className="row gx-0 gy-4">
                            <div className="col-lg-6 col-md-6">
                                <div className="select-box">
                                    <div className="searchbox-icons">
                                        <i className="bi bi-geo-alt" />
                                    </div>
                                    <div className="searchbox-input">
                                        <label htmlFor="activity-dropdown">Type</label>
                                        <Select
                                            placeholder=""
                                            className="selectbox"
                                            onChange={setSelectedOption}
                                            defaultValue={selectedOption}
                                            options={packageOptions}
                                            touchUi={false}
                                            showGroupWheel={true}
                                        ></Select>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="select-box">
                                    <div className="searchbox-icons">
                                        <i className="bi bi-geo-alt" />
                                    </div>
                                    <div className="searchbox-input">
                                        <label htmlFor="activity-dropdown">Location</label>
                                        <Select
                                            placeholder=""
                                            className="selectbox"
                                            onChange={setprovinceOptions}
                                            defaultValue={provinceOption}
                                            options={provinceOptions}
                                            touchUi={false}
                                            showGroupWheel={true}
                                        ></Select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
          <div className="row g-4">
          { selectedOption.value != null || provinceOption.value != 0 ? fiteredTours.map((tour) => (
                        <div className="col-lg-4 col-md-6">
                        <PackageCard
                          id={tour.id}
                          image={tour.featuredImage}
                          date={tour.duration}
                          title={tour.title}
                          highlight={tour.highlight}
                          price={tour.price}
                          tour={tour}
                        />
                      </div>      
                )) : data.activeTours?.edges.map((tour) => (
            <div className="col-lg-4 col-md-6">
            
              <PackageCard
                id={tour.id}
                image={tour.featuredImage}
                date={tour.duration}
                title={tour.title}
                highlight={tour.highlight}
                price={tour.price}
                tour={tour}
              />
            </div>        
             ))}
          </div>
          {/* { selectedOption.value != null || provinceOption.value != 0 ? (
           <Pagination page={page} total={tours.length/12} url={"/package?"} /> 
                ) : (
                  <Pagination page={page} total={data.activeTours?.totalCount/12} url={"/package?"} />    
             )} */}
          
        </div>
      </div>
      {/* ===============  Package gird area end =============== */}
    </>
  );
}

export default PackageGridWrapper;
