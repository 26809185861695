import React from 'react'
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Layout from "./components/App";
import MainLayout from "./components/layout/MainLayout";
import About from "./components/pages/about/About";
import BlogSidebar from "./components/pages/blog/BlodSidebar/BlogSidebar";
import BlogDetailsPage from "./components/pages/blog/blogdetails/BlogDetailsPage";
import BlogStandard from "./components/pages/blog/BlogStandard/BlogStandard";
import Contact from "./components/pages/contact/Contact";
import DestinationDetails from "./components/pages/destinationDetails/DestinationDetails";
import Destinations from "./components/pages/destinetion/Destinatination";
import Error from "./components/pages/error/Error";
import FaqPage from "./components/pages/faq/FaqPage";
import GuidePage from "./components/pages/guide/GuidePage";
import PackageDetails from "./components/pages/packageDetails/PackageDetails";
import PackageGrid from "./components/pages/packageGrid/PackageGrid";
import PackageSidebar from "./components/pages/packageSidebar/PackageSIdebar";
import PackageStandard from "./components/pages/packageStandard/PackageStandard";
import GallaryPage from "./components/pages/gallary/GallaryPage";
import BlogGridPage from "./components/pages/blog/BlogGrid/BlogGridPage";
import SimpleReactLightbox from "simple-react-lightbox";
import ChekoutWrap from './components/pages/checkout/CheckOutWrap';
import PaymentResultPage from './components/pages/payment/Result'

// import all css
import "./index.css";

import { ApolloClient, InMemoryCache, ApolloProvider, gql } from '@apollo/client';
import TermAndCondPage from './components/pages/term/TermAndCond';
import PrivacyPage from './components/pages/PrivacyPolicy/Privacy';

//Alert 
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import TransferWrapper from "./components/pages/transfer/TransferWrapper";
import RentalDetail from "./components/pages/transfer/rental-detail/RentalDetail";
import RentalDetails from "./components/pages/transfer/rental-detail/RentalDetail";
import TransferDetails from "./components/pages/transfer/transfer-details/TransferDetails";
import TransferList from "./components/pages/transfer/TransferList";
import RentalList from "./components/pages/transfer/RentalList";

/*
 * Version :Tourx-pro 0.1
 * Event : Rendering all content to web.
 * Actions: Define all routes and page.
 * @return html
 * */
// default Warning Error hide

const client = new ApolloClient({
  uri:  'https://juukkoo.com/api/graphql',
  cache: new InMemoryCache(),
});

const options = {
  // you can also just use 'bottom center'
  position: positions.MIDDLE,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}

function Root() {
  return (
    <>
    <BrowserRouter basename="/">
      <Switch>
        {/*main layout*/}
        <Route exact path="/" component={MainLayout} />
        {/* secound layout */}
       
        {/* all inner page load layout component */}
        <Layout>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/about`}
            component={About}
          />

          {/* desination pages */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/destination`}
            component={Destinations}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/destination-details`}
            component={DestinationDetails}
          />

<Route
            exact
            path={`${process.env.PUBLIC_URL}/terms-conditions`}
            component={TermAndCondPage}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/privacy-policy`}
            component={PrivacyPage}
          />

          {/* all package pages component */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/package`}
            component={PackageGrid}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/package-sidebar`}
            component={PackageSidebar}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/package-details/:id`}
            component={PackageStandard}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/package-details`}
            component={PackageDetails}
          />
             <Route
            exact
            path={`${process.env.PUBLIC_URL}/check-out`}
            component={ChekoutWrap}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/payment-result/success`}
            component={PaymentResultPage}
          />
            {/* all transfer pages */}
            <Route
                exact
                path={`${process.env.PUBLIC_URL}/transfer`}
                component={TransferList}
            />
            <Route
                exact
                path={`${process.env.PUBLIC_URL}/rental`}
                component={RentalList}
            />
            <Route
                exact
                path={`${process.env.PUBLIC_URL}/rental-details/`}
                component={RentalDetails}
            />
            <Route
                exact
                path={`${process.env.PUBLIC_URL}/transfer-details/`}
                component={TransferDetails}
            />
          {/* all blog pages */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog`}
            component={BlogGridPage}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-details`}
            component={BlogDetailsPage}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-standard`}
            component={BlogStandard}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-sidebar`}
            component={BlogSidebar}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/guide`}
            component={GuidePage}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/faq`}
            component={FaqPage}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/gallary`}
            component={GallaryPage}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/contact`}
            component={Contact}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/error`}
            component={Error}
          />
        </Layout>
      </Switch>
    </BrowserRouter>
  </>
  )
}

export default Root
ReactDOM.render(
  <React.StrictMode>
    <SimpleReactLightbox>
    <AlertProvider template={AlertTemplate} {...options}>
    <ApolloProvider client={client}>
      <Root />
      </ApolloProvider>
    </AlertProvider>
    </SimpleReactLightbox>
  </React.StrictMode>,
  document.getElementById("root")
);
