import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import TermsWrap from "./TermsWrap"

function TermAndCondPage() {
  return (
    <>
      <Breadcrumb name="Terms and conditions" />
      <TermsWrap />
    </>
  );
}

export default TermAndCondPage;