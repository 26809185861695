import React from "react";
import { Link } from "react-router-dom";

function TransferCard(props) {
    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    return (
        <>
            <div className="package-card-alpha">
                <div className="package-card-body">
                    <h3 className="p-card-title">
                        <Link
                            onClick={scrollTop}
                            to={{pathname: `${process.env.PUBLIC_URL}/transfer-details`, state: props}}
                        >
                            {props.transfer.origin.name} to {props.transfer.destination.name} {props.transfer.round ? "(Round trip)" : ""}
                        </Link>
                    </h3>
                    <div className="p-card-bottom">
                        <div className="book-btn">
                            <Link
                                onClick={scrollTop}
                                to={{pathname: `${process.env.PUBLIC_URL}/transfer-details`, state: props}}
                            >
                                Book Now <i className="bx bxs-right-arrow-alt" />
                            </Link>
                        </div>
                        <div className="p-card-info">
                            <span>From</span>
                            <h6>
                                {props.transfer.price}
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TransferCard;
