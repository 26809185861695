import React from "react";
import AboutWrapperArea from "./AboutWrapperArea";
import Breadcrumb from "../../common/Breadcrumb";

function About() {
  return (
    <>
      <Breadcrumb name="About" />
      <AboutWrapperArea />
    </>
  );
}

export default About;
