import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Pagination(props) {
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const [pages, setPages] = useState([]);
  const is_active = (i) => props.page === i ? "page-item active" : "page-item";
 
  // const getPage = totalpage => {
  //   let content = [];
  //   for (let i = 0; i < totalpage; i++) {
  //     content.push(<li className={is_active(i+1)}>
  //     <Link to={props.url+"page="+(i+1)} onClick={scrollTop} className="page-link">
  //       {i+1}
  //     </Link>
  //   </li>);
  //   }
  //   return content;
  // };

  useEffect(() => {
    let content = [];
    for (let i = 0; i < props.total; i++) {
      content.push(<li className={parseInt(props.page) === i+1 ? "page-item active" : "page-item"}>
      <Link onClick={scrollTop} className="page-link">
        {i+1}
      </Link>
    </li>);
    }
    setPages(content)
  }, [props.page]);

  return (

    <>
      <div className="row">
        <div className="col-lg-12">
          <nav>
            <ul className="pagination pagination-style-one justify-content-center pt-50">
              { props.page > 1 &&
              <li className="page-item page-arrow">
                <Link  onClick={scrollTop} className="page-link">
                  <i className="bi bi-chevron-double-left" />
                </Link>
              </li>
              }
              {pages}
              { props.page < props.total &&
              <li className="page-item page-arrow">
                <Link  onClick={scrollTop} className="page-link">
                  <i className="bi bi-chevron-double-right" />
                </Link>
              </li>
              }
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Pagination;
