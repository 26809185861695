import React from "react";
import { Link } from "react-router-dom";
import PackageCard from "../packageGrid/PackageCard";
import { gql, useQuery } from '@apollo/client';

function PackageArea() {
  const GET_FEATURED_TOUR = gql`
  query ActiveTours($limit: Int, $offset: Int, $isFeatured: Boolean) {
    activeTours(limit: $limit, offset: $offset, isFeatured: $isFeatured) {
      edges {
        id
        title
        highlight
        content
        tourType
        price
        salePrice
        highSeason
        checkIn
        checkOut
        duration
        days
        nights
        bookingPeriod
        minPeople
        maxPeople
        isActive
        isFeatured
        featuredImage
        rating
        locations {
          id
          location
        }
      }
      message
      ok
      totalCount
    }
  }
`;

const { loading, error, data } = useQuery(GET_FEATURED_TOUR, {
  variables: {
    "limit": 15,
    "offset": 0,
    "isFeatured": true,
  }
});

if (loading) return 'Loading...';
if (error) return `Error! ${error.message}`;
  const scrolltop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <>
      <div className="package-area package-style-one pt-110 ">
        <div className="container">
          <div className="row  align-items-center">
            <div className="col-lg-8">
              <div className="section-head-alpha">
                <h2>Popular Tour Package</h2>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="package-btn text-lg-end">
                <Link
                  to={`${process.env.PUBLIC_URL}/package`}
                  onClick={scrolltop}
                  className="button-fill-primary"
                >
                  View All Tour
                </Link>
              </div>
            </div>
          </div>
          <div className="row g-4">
          {data.activeTours.edges.map((tour) => (
            <div className="col-lg-4 col-md-6">
              <PackageCard
                id={tour.id}
                image={tour.featuredImage}
                date={tour.duration}
                title={tour.title}
                highlight={tour.highlight}
                price={tour.price}
                tour={tour}
              />
            </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default PackageArea;
