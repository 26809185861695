import React from "react";
import { Link } from "react-router-dom";

function RentalCard(props) {
    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    return (
        <>
            <div className="package-card-alpha">
                <div className="package-thumb">
                    <img  style={{ width: "100%"}} src={props.image}  alt="images" />
                    <p className="card-lavel">
                        <i className="bi bi-clock" /> <span>{props.date} Hrs.</span>
                    </p>
                </div>
                <div className="package-card-body">
                    <h3 className="p-card-title">
                        <Link
                            onClick={scrollTop}
                            to={{pathname: `${process.env.PUBLIC_URL}/rental-details`, state: props}}
                        >
                            {props.title}
                        </Link>
                    </h3>
                    <div className="p-card-bottom">
                        <div className="book-btn">
                            <Link
                                onClick={scrollTop}
                                to={{pathname: `${process.env.PUBLIC_URL}/rental-details`, state: props}}
                            >
                                Book Now <i className="bx bxs-right-arrow-alt" />
                            </Link>
                        </div>
                        <div className="p-card-info">
                            <span>From</span>
                            <h6>
                                {props.price}
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RentalCard;
