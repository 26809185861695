import React, { useState } from "react";
import { SRLWrapper } from "simple-react-lightbox";
import ModalVideo from "react-modal-video";
import { gql, useLazyQuery  } from '@apollo/client';
import parse from 'html-react-parser';

function PackageDetailsTab(props) {

  const [isOpen, setOpen] = useState(false);
  const tour = props.tour;  
  const GET_ITINERARIES = gql`
  query Itineraries($tourId: UUID!) {
    itineraries(tourId: $tourId) {
      id
      no
      name
      time
      description
    }
  }
`;

const GET_IMG = gql`
query GetImages($getImagesId: UUID!) {
  getImages(tourId: $getImagesId) {
    id
    url
    imageOrder
  }
}
`;

const [getItineries, itineraries] = useLazyQuery(GET_ITINERARIES, {
  variables: { 
    "tourId": props.id
},
});

const [getImages, img_data] = useLazyQuery(GET_IMG, {
  variables: { 
    "getImagesId": props.id
},
});


  return (
    <>
      <div className="package-details-tabs">
        <ul
          className="nav nav-pills tab-switchers gap-xxl-4 gap-3"
          id="pills-tab"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="pills-package1"
              data-bs-toggle="pill"
              data-bs-target="#pill-body1"
              type="button"
              role="tab"
              aria-controls="pill-body1"
              aria-selected="true"
            >
              <i className="bi bi-info-lg" /> Information
            </button>
          </li>
          {/*<li className="nav-item" role="presentation">*/}
          {/*  <button*/}
          {/*    className="nav-link"*/}
          {/*    id="pills-package2"*/}
          {/*    data-bs-toggle="pill"*/}
          {/*    data-bs-target="#pill-body2"*/}
          {/*    type="button"*/}
          {/*    role="tab"*/}
          {/*    aria-controls="pill-body2"*/}
          {/*    aria-selected="false"*/}
          {/*    onClick={() => getItineries()}*/}
          {/*  >*/}
          {/*    <i className="bi bi-file-earmark-spreadsheet" />*/}
          {/*    Travel Plan*/}
          {/*  </button>*/}
          {/*</li>*/}
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="pills-package3"
              data-bs-toggle="pill"
              data-bs-target="#pill-body3"
              type="button"
              role="tab"
              aria-controls="pill-body3"
              aria-selected="false"
              onClick={() => getImages()}
            >
              <i className="bi bi-images" /> Tour Gallary
            </button>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          {/* package info tab */}
          <div
            className="tab-pane fade show active package-info-tab mt-5"
            id="pill-body1"
            role="tabpanel"
            aria-labelledby="pills-package1"
          >
            <h3 className="d-subtitle">Package Details</h3>
            <p>
              {parse(props.tour.content)}
            </p>   
          </div>
          {/* package plans tab */}
{/*          <div*/}
{/*            className="tab-pane fade package-plan-tab tab-body"*/}
{/*            id="pill-body2"*/}
{/*            role="tabpanel"*/}
{/*            aria-labelledby="pills-package2"*/}
{/*          >*/}
{/*            <h3 className="d-subtitle">Details</h3>*/}
{/*            <p>*/}
{/*            {parse(props.tour.highlight)}*/}
{/*            </p>*/}
{/*            {itineraries?.data && <div className="accordion plans-accordion" id="planAccordion">*/}
{/*              */}
{/*            {itineraries.data.itineraries.map((itinerary, index) => (*/}
{/*            <div className="accordion-item plans-accordion-single">*/}
{/*                <div className="accordion-header" id="planHeadingOne">*/}
{/*                  <div*/}
{/*                    className="accordion-button"*/}
{/*                    data-bs-toggle="collapse"*/}
{/*                    data-bs-target="#planCollapse1"*/}
{/*                    role="navigation"*/}
{/*                  >*/}
{/*                    <div className="paln-index-circle">*/}
{/*                      <h4>{index}</h4>*/}
{/*                    </div>*/}
{/*                    <div className="plan-title">*/}
{/*                      <h5>{itinerary.name}</h5>*/}
{/*                      <h6>{itinerary.time}</h6>*/}
{/*                    </div>*/}
{/*                  </div>*/}
{/*                </div>*/}
{/*                <div*/}
{/*                  id="planCollapse1"*/}
{/*                  className="accordion-collapse collapse show"*/}
{/*                  aria-labelledby="planHeadingOne"*/}
{/*                  data-bs-parent="#planAccordion"*/}
{/*                >*/}
{/*                  <div className="accordion-body plan-info">*/}
{/*                    <p>*/}
{/*                      {itinerary.description}*/}
{/*                    </p>*/}
{/*                  </div>*/}
{/*                </div>*/}
{/*              </div>*/}
{/*            ))}*/}
{/*            </div>*/}
{/*}*/}
{/*          </div>*/}
          {/* package gallary tab */}
          <div
            className="tab-pane fade package-gallary-tab"
            id="pill-body3"
            role="tabpanel"
            aria-labelledby="pills-package3"
          >
            {img_data?.data &&
            <SRLWrapper>
              <div className="row g-4">
              {img_data.data.getImages.map((img) => (
              <div className="col-6">
                  <div className="package-gallary-item">
                    <a
                      href={
                        img.url
                      }
                      data-fancybox="gallery"
                      data-caption="Caption Here"
                    >
                      <img
                        src={
                          img.url
                        }
                        alt="PackageIMG"
                      />
                    </a>
                  </div>
                </div>
              ))}
              </div>
          
            </SRLWrapper>
            }
          </div>
        </div>
      </div>
      <React.Fragment>
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId="L61p2uyiMSo"
          onClose={() => setOpen(false)}
        />
      </React.Fragment>
    </>
  );
}

export default PackageDetailsTab;
