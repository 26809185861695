import React from "react";
import { Link } from "react-router-dom";

function ContactWrapperArea() {
  return (
    <>
      <div className="contact-wrapper pt-110">
        <div className="container">
          <div className="row align-items-center justify-content-lg-center gy-5">
            <div className="col-lg-6">
              <div className="contatc-intro-figure">
                <img
                  src={process.env.PUBLIC_URL + "/images/banner/contact-bg.png"}
                  alt="ContactBannerIMG"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="contact-info">
                <h3>Contact Info.</h3>
                <ul>
                  <li>
                    <h6>Let’s Talk</h6>
                    <a href="mailto:info@juukkoo.com">info@juukkoo.com</a>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="tel:+6695-254-4858"
                    >
                      (+66) 95-254-4858
                    </a>
                  </li>
                  <li>
                    <h6>Location.</h6>
                    <a href="#">
                      99/240 Moo 5, Mooban Casa Premium (Ratchapruek-Rama5),<br/>
                      Bangkuay-Sainoi Road, Bangkrang, Muang, Nonthaburi 11000, Thailand
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container pt-110">
          <form onSubmit={(e) => e.preventDefault()} id="contact_form">
            <div className="contact-form-wrap">
              <h4>Get a free key stroke quote now</h4>
              <p>
                Your email address will not be published. Required fields are
                marked *
              </p>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">Name</label>
                    <input
                      required
                      type="text"
                      placeholder="Your name"
                      id="name"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="email">Email</label>
                    <input
                      required
                      type="text"
                      placeholder="Your Email"
                      id="email"
                    />
                  </div>
                </div>
              </div>
              <div className="custom-input-group">
                <textarea
                  cols={20}
                  rows={7}
                  required
                  placeholder="Your message"
                  defaultValue={""}
                />
              </div>
              <div className="custom-input-group">
                <div className="submite-btn">
                  <button type="submit">Send Message</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ContactWrapperArea;
