import React from "react";
import RentalDetailsWrap from "./RentalDetailsWrap";
import Breadcrumb from "../../../common/Breadcrumb";


function RentalDetails(props) {
    const { state } = props.location;
    const { rental } = state;
    
    return (
        <>
            <Breadcrumb name="Rental Details"/>
            <RentalDetailsWrap rental={rental} />
        </>
    )
}

export default RentalDetails