import React from "react";

function PaymentResultPage() {
  return (
    <>
    <div style={{marginTop: 200, marginBottom: 100, marginLeft: 100}}>
      <h2>Payment Success</h2>
      <p>
      We want to ensure that you have a memorable and enjoyable experience during your trip.<br/>

Moreover, as a token of our appreciation, we’d like to offer you a special promotion for your next booking with us. <br/>

Once again, thank you for choosing us 🥰. If you have any further questions or need assistance, please feel free to contact us at info@juukkoo.com. We’re here to help.
        </p>
        </div>
    </>
  );
}

export default PaymentResultPage;