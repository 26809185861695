import React from "react";
import { Link } from "react-router-dom";

function PrivacyWrap() {
  return (
    <>
      <div className="blog-details-wrapper pt-110">
        <div className="container">
          <div className="row">
          <div className="col-lg-12">
        <div className="blog-details">
          <div className="post-header">
            <h2 className="post-title">
            Privacy
            </h2>
          </div>
          <div className="post-header">Cookies Policy</div>
          <div className="post-body">
            <p>
            We or our website www.juukkoo.com acting as an intermediary between customers and manufacturing partner companies
                  Including travel service providers such as hotels, tourist attractions, shuttle buses, restaurants, tour guides,
                  miscellaneous work, etc. We use cookies and other tools to collect information. Share information and use your information
                  that we receive through your device as well as your personal information that you have given us by filling in or
                  commenting on our website. To develop the website to be able to provide maximum benefit to the people who visit the
                  website and to facilitate the customers who use our website according to your booking needs. We have appropriate measures
                  in place to preserve and protect the information we receive. Taking into account the utmost security in every step of the
                  operation of our website. By continuing to use this website, you agree that we may place cookies on your device. You can
                  opt-out of receiving email news from us by changing the settings on your user account by continuing to use this website,
                  you agree that we may place cookies on your device. Whenever you want to withdraw your consent to the use of cookies, you
                  will need to set your browser to remove cookies from each browser you use. If you would like more information on how to do
                  so. Please select a topic.
                </p>
                <p>
                  We have appropriate measures in place to preserve and protect the information we receive. Taking into account the utmost
                  security in every step of the operation of our website. By continuing to use this website, you agree that we may place
                  cookies on your device. You can opt-out of receiving email news from us by changing the settings on your user account by
                  continuing to use this website, you agree that we may place cookies on your device. Whenever you want to withdraw your
                  consent to the use of cookies, you will need to set your browser to remove cookies from each browser you use. If you would
                  like more information on how to do so. Please select a topic.
                </p>
     
            <h3 className="sub-title">Help in your internet browser to study in more detail.</h3>
            <p className="mt-1">
            With your consent, we may send you notification messages for the services you choose to reserve or notify you of market
                  news. We take reasonable measures to share your information. Coordinating the services you choose to use on this website
                  with the manufacturer's partner companies. Including our travel service providers such as hotels, attractions, shuttle
                  buses, restaurants, tour guides, miscellaneous work, etc.
            </p>
  
            <h3 className="sub-title">Setting cookies for use</h3>
            <p className="mt-1">
            We or our website www.juukkoo.com uses cookies and other data collection tools. Share information and may use your
                  personal data and your device for our purposes. If you need more information, please click "Cookie Policy" and "Privacy
                  Policy". By continuing to use this website, you agree that we may place cookies on your device.
            </p>
            <h3 className="sub-title">Strictly necessary</h3>
            <p className="mt-1">
            These cookies are required for you to access our website and cannot be disabled.
            </p>
               
            <h3 className="sub-title">Preference Cookies</h3>
            <p className="mt-1">
            These cookies help us to remember your device, as well as the activities you do while using our website such as filling in
                  personal information opinions, etc.
            </p>
            <h3 className="sub-title">Statistics Cookies</h3>
            <p className="mt-1">
            These cookies collect the pages you visit, how long you use, and connecting the link you clicked. This section will not be
                  able to identify you. It is a combination of information without disclosure.
            </p>
            <h3 className="sub-title">Marketing Cookies</h3>
            <p className="mt-1">
            These cookies track your online activity to help us show you more ads that are relevant to your interests. These cookies
                  can share that information with other organizations or advertisers.
            </p>
            <h3 className="sub-title">Do not sell my personal information</h3>
            <p className="mt-1">
            Please note that when you visit our website, we and our partners may set cookies or use similar technologies on your
                  device. Our partners may share this information with third parties. You should consult the cookie policy and privacy
                  policy of the partner manufacturers including the travel service providers listed on this website.
            </p>
          </div>
        </div>
      </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyWrap;
