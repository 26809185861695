import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { gql, useLazyQuery } from '@apollo/client';

function WidgetPackageOffer() {

  const GET_FEATURED_TOUR = gql`
  query Tours($limit: Int, $offset: Int, $isFeatured: Boolean, $search: String) {
    tours(limit: $limit, offset: $offset, isFeatured: $isFeatured, search: $search) {
      edges {
        id
        title
        highlight
        content
        tourType
        price
        salePrice
        highSeason
        checkIn
        checkOut
        duration
        days
        nights
        bookingPeriod
        minPeople
        maxPeople
        isActive
        isFeatured
        featuredImage
        rating
        locations {
          id
          location
        }
      }
      message
      ok
      totalCount
    }
  }
`;
const [getTours, toursRes] = useLazyQuery(GET_FEATURED_TOUR);
useEffect(() => {
  getTours({
    variables: { 
      "limit": 4,
      "offset": 0,
      "isFeatured": true,
      "search": null
  }});
}, []);

if (toursRes.loading) return 'Loading...';
if (toursRes.error) return `Error! ${toursRes.error.message}`;

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <aside className="package-widget-style-2 widget-recent-package-entries mt-30">
        <div className="widget-title text-center">
          <h4>Offer Package</h4>
        </div>
        <div className="widget-body">
          <ul>
          {toursRes.data?.tours.edges && toursRes.data.tours.edges.map((tour) => (
            <li className="package-sm">
              <div className="thumb">
                <Link
                  onClick={scrollTop}
                  to={{pathname: `${process.env.PUBLIC_URL}/package-details`, state: tour}}
                >
                  <img src={tour.featuredImage} alt="offer" />
                </Link>
              </div>
              <div className="info">
                <h6>
                  <Link
                    onClick={scrollTop}
                    to={{pathname: `${process.env.PUBLIC_URL}/package-details`, state: tour}}
                  >
                    {tour.title}
                  </Link>
                </h6>
                <div className="price">
                  <span>From</span>
                  <h6>
                    ฿{tour.price} <span>Per Person</span>
                  </h6>
                </div>
              </div>
            </li>
          ))}
          </ul>
        </div>
      </aside>
    </>
  );
}

export default WidgetPackageOffer;
