import React, {useEffect, useState} from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import {gql, useLazyQuery, useQuery} from "@apollo/client";
import Select from "react-select";
import TransferCard from "./TransferCard";
import queryString from "query-string";
import RentalCard from "./RentalCard";

function RentalList(props) {

    const GET_RENTAL = gql`
    query Rental($limit: Int!, $offset: Int!) {
  rental(limit: $limit, offset: $offset) {
    ok
    message
    data {
      id
      code
      name
      description
      min_hrs
      max_hrs
      vehicle {
        id
        name
        description
        image
        max_passenger
      }
      price
    }
    total
  }
}
  `;

    const [getRentals, RentalsRes] = useLazyQuery(GET_RENTAL);
    const [page, setPage] = useState(1);
    const [startDate, setStartDate] = useState(new Date());
    const [rentals, setRentals] = useState([]);
    let offset = 0;
    let limit = 20;
    const { search } = useLocation()
    const history = useHistory();
    useEffect(() => {
        let values = queryString.parse(search)
        let page_no = values.page ? values.page : 1;
        setPage(page_no)
    }, [search]);

    useEffect(() => {
        getRentals({
            variables: {
                "limit": limit,
                "offset": offset*page,
            }});
    }, [page]);
    const { loading, error, data } = useQuery(GET_RENTAL, {
        variables: {
            "limit": limit,
            "offset": page === 1? 0: page*limit,
        },
    });

    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;
    if (RentalsRes.loading) return 'Loading...';
    if (RentalsRes.error) return `Error! ${RentalsRes.error.message}`;

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const onSubmit = (data) =>  {
        let order = {
            order_date: startDate,
            order_items: [
                {
                    "item_id": props.rental.id,
                    "item_name": props.rental.name,
                    "qty": 1,
                    "price": props.rental.price,
                    "item_type": "rental"
                },
            ],
            "product_id": props.rental.id,
            "product_name": props.rental.name,
            "product_highlight": props.rental.description,
            "product_type": "rental",
            "remark": data.remark,
            "total": props.rental.price
        }
        scrollTop();
        history.push('/check-out', { order });
    }

    return (
        <>
            {/* ===============  Package gird area start =============== */}
            <div className="package-wrapper">
                <div className="container">
                    <div className="row g-4 mt-3">
                        {data.rental?.data.map((rental) => (
                            <div className="col-4">
                                <RentalCard
                                    id={rental.id}
                                    image={rental.vehicle.image}
                                    date={rental.max_hrs}
                                    title={rental.name}
                                    highlight={rental.description}
                                    price={rental.price}
                                    rental={rental}
                                />
                            </div>
                        ))
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default RentalList;
