import React from "react";
import TransferForm from "./TransferForm";

function TransferDetailsWrap(props) {
    return (
        <>
            <div className="package-details-wrapper pt-110">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="tour-package-details">
                                <div className="row">
                                    <h2>{props.transfer.origin.name} to {props.transfer.destination.name}</h2>
                                </div>
                                <div className="row mt-5">
                                    {props.transfer.destination.id === 5 || props.transfer.destination.id === 4 ? (
<>
<p>Transfer Out/Airport Transfer Out : Pick up from XXX to The Airport&nbsp;<br/>*Private Transfer Service<br/>*We will keep waiting 15-20 minutes at the meeting point after the service time reserved and consider that the service has been completed and non-refundable will be applied.&nbsp;<br/>*If you would like to change the service time reserved, please inform us at least 12 hours in advance.<br/>*The meeting point can be the lobby area or the convenience area for pick up/drop off<br/>*Please show the booking confirmation documents for the service provider on the day of service.<br/>*Please be informed that the pick up area outside Bangkok which are not mention at below list may have the additional fee to settle payment with the driver.<br/>Ayuthaya : Bang Sai, Bang Pa-In, Phra Nakorn Sri Ayuthaya<br/>Pattaya : Banglamung, Nakrue , Pattaya<br/>U-Tapao : Airport only<br/>Ban Phe Pier : Ban Phe Pier only<br/>Rayong : Tapong, Phe, Kang, Mab Ta Put<br/>Trat : Muang Trat<br/>Koh Chang+Ferry : Koh Chang<br/>Cha am : Muang Cha am<br/>Hua Hin : Muang Hua Hin<br/>Khaoyai : Mu Sri, Kanong Phra, Nong Sarai<br/>Kanchanaburi : Tha Mung, Tha Maka, Muang kan</p>
</>
                                    ) : props.transfer.origin.id === 5 || props.transfer.origin.id === 4 ? (
                                        <>
                                           <p>Transfer In/Airport Transfer In : Pick up from The Airport&nbsp;<br/>*Private Transfer Service<br/>*The meeting point for Don Mueang Airport (DMK) is at GATE No.5 with display the nameplate.<br/>*The meeting point for Suvarnabhumi Airport (BKK) is at GATE No.4 with display the nameplate.<br/>*If you would like to change the service time reserved, please inform us at least 12 hours in advance.<br/>*We will keep waiting 120 minutes at the meeting point after the service time reserved and consider that the service has been completed and non-refundable will be applied.&nbsp;<br/>*We will Pick up/Drop off at the hotel’s car park.<br/>*Please show the booking confirmation documents for the service provider on the day of service.<br/>*We are not responsible in the event of an incident that cannot be controlled that are not caused by the company, such as flight cancelled, natural disasters, riots, road collapses, etc.<br/>*Please be informed that the drop off area outside Bangkok which are not mention at below list may have the additional fee to settle payment with the driver.<br/>Ayuthaya : Bang Sai, Bang Pa-In, Phra Nakorn Sri Ayuthaya<br/>Pattaya : Banglamung, Nakrue , Pattaya<br/>U-Tapao : Airport only<br/>Ban Phe Pier : Ban Phe Pier only<br/>Rayong : Tapong, Phe, Kang, Mab Ta Put<br/>Trat : Muang Trat<br/>Koh Chang+Ferry : Koh Chang<br/>Cha am : Muang Cha am<br/>Hua Hin : Muang Hua Hin<br/>Khaoyai : Mu Sri, Kanong Phra, Nong Sarai<br/>Kanchanaburi : Tha Mung, Tha Maka, Muang kan</p>
                                        </>
                                    ) : (
                                        <>
                                        <p>Bangkok City To Outside BKK : Pick up from BKK<br/>*Private Transfer Service<br/>*We will keep waiting 15-20 minutes at the meeting point after the service time reserved and consider that the service has been completed and non-refundable will be applied.&nbsp;<br/>*If you would like to change the service time reserved, please inform us at least 12 hours in advance.<br/>*The meeting point can be the lobby area or the convenience area for pick up/drop off<br/>*Please show the booking confirmation documents for the service provider on the day of service.<br/>*Please be informed that the drop off area outside Bangkok which are not mention at below list may have the additional fee to settle payment with the driver.<br/>Ayuthaya : Bang Sai, Bang Pa-In, Phra Nakorn Sri Ayuthaya<br/>Pattaya : Banglamung, Nakrue , Pattaya<br/>U-Tapao : Airport only<br/>Ban Phe Pier : Ban Phe Pier only<br/>Rayong : Tapong, Phe, Kang, Mab Ta Put<br/>Trat : Muang Trat<br/>Koh Chang+Ferry : Koh Chang<br/>Cha am : Muang Cha am<br/>Hua Hin : Muang Hua Hin<br/>Khaoyai : Mu Sri, Kanong Phra, Nong Sarai<br/>Kanchanaburi : Tha Mung, Tha Maka, Muang kan</p>
                                            <ul>
                                                <li>Private Service</li>
                                                <li>We will keep waiting 15-20 minutes at the meeting point after the
                                                    service time is reserved and consider that the service has been
                                                    completed and non-refundable will be applied.&nbsp;</li>
                                                <li>If you would like to change the service time reserved, please inform
                                                    us at least 12 hours in advance.
                                                </li>
                                                <li>The meeting point can be the lobby area or the convenience area for
                                                    pickup/drop-off
                                                </li>
                                                <li>Please show the booking confirmation documents to the service
                                                    provider on the day of service.
                                                </li>
                                            </ul>
                                        </>
                                    )}
                                </div>
                                <div className="row mt-2">
                                    <div className="col-lg-4"><img style={{width: "100%"}} src={props.transfer.vehicle.image} alt="transfer"/></div>
                                    <div className="col-lg-8" dangerouslySetInnerHTML={{__html:props.transfer.vehicle.description}} />

                                    </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="package-sidebar">
                                <TransferForm transfer={props.transfer}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TransferDetailsWrap;