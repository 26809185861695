import React from "react";
import RentalForm from "./RentalForm";

function RentalDetailsWrap(props) {
    
    return (
        <>
            <div className="package-details-wrapper pt-110">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="tour-package-details">
                                <div className="row">
                                    <h2>{props.rental.name}</h2>
                                </div>
                                <div className="row mt-5">
                                <p>Rent Van 3,6,10 Hr<br/>*Private Transfer Service<br/>*Please arrive at the meeting point at least 5-10 minute before service time.<br/>*The meeting point can be the lobby area or the convenience area for pick up/drop off.<br/>*Start counting from the pick-up time.<br/>*Fractions of a minute are calculated as 1 hour.<br/>*if you are out of time or want to extend your time there is an additional fee.<br/>*Please show the booking confirmation documents for the service provider on the day of service.<br/>*This price does not include expressway fees and parking fees.&nbsp;<br/>*The additional fees can pay directly to the driver.<br/>*Please show the booking confirmation documents for the service provider on the day of service.</p>
                                    <ul>
                                        <li>Private Service</li>
                                        <li>Please arrive at the meeting point at least 5-10 minutes before service
                                            time.
                                        </li>
                                        <li>The meeting point can be the lobby area or the convenience area for
                                            pickup/drop off.
                                        </li>
                                        <li>Start counting from the pick-up time.</li>
                                        <li>Fractions of a minute are calculated as 1 hour.</li>
                                        <li>if you are out of time or want to extend your time, there is an additional
                                            fee.
                                        </li>
                                        <li>Please show the booking confirmation documents to the service provider on
                                            the day of service.
                                        </li>
                                        <li>This price does not include expressway fees and parking fees.&nbsp;</li>
                                        <li>The additional fees can be paid directly to the driver.</li>
                                        <li>Please show the booking confirmation documents to the service provider on
                                            the day of service.
                                        </li>
                                    </ul>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-lg-4"><img style={{width: "100%"}}
                                                                   src={props.rental.vehicle.image} alt="transfer"/>
                                    </div>
                                    <div className="col-lg-8"
  dangerouslySetInnerHTML={{
    __html: props.rental.vehicle.description
  }}></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="package-sidebar">
                                <RentalForm rental={props.rental}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RentalDetailsWrap;
