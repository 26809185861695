import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import PackageDetailsWrap from "./PackageDetailsWrap";


function PackageDetails(props) {
    const { state } = props.location;
    const  tour  = state;

  return (
    <>
      <Breadcrumb name="Package Details"/>
      <PackageDetailsWrap tour={tour} />
    </>
  )
}

export default PackageDetails
