import React from "react";
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import PackageStandardWrap from "./PackageStandardWrap";

function PackageStandard() {
  const GET_TOUR = gql`
  query Tour($id: UUID!) {
    tour(id: $id) {
      data {
        id
        title
        highlight
        content
        tourType
        price
        salePrice
        highSeason
        checkIn
        checkOut
        duration
        days
        nights
        bookingPeriod
        minPeople
        maxPeople
        isActive
        isFeatured
        featuredImage
        rating
        locations {
          id
          location
        }
      }
      message
      ok
    }
  }
`;

  const { id } = useParams();

  const { loading, error, data } = useQuery(GET_TOUR, {variables: { 
      "id": id,
  }
});
if (loading) return "Loading...";
if (error) return `Error! ${error.message}`;

  console.log(data);
  return (
    <>
      <PackageStandardWrap tour={data.tour.data} />
    </>
  );
}

export default PackageStandard;
