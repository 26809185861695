import React from "react";
import Breadcrumb from "../../../common/Breadcrumb";
import TransferDetailsWrap from "./TransferDetailsWrap";


function TransferDetails(props) {
    const { state } = props.location;
    const { transfer } = state;

    return (
        <>
            <Breadcrumb name="Transfer Details"/>
            <TransferDetailsWrap transfer={transfer} />
        </>
    )
}

export default TransferDetails