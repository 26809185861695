import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import {gql, useQuery} from "@apollo/client";
import Select from "react-select";
import TransferCard from "./TransferCard";

function TransferList(props) {

    const GET_TRANSFER = gql`
    query Transfers($limit: Int!, $offset: Int!) {
  transfers(limit: $limit, offset: $offset) {
    ok
    message
    data {
      id
      code
      origin {
        id
        name
        address
        province
        lat
        lng
      }
      destination {
        id
        name
        address
        province
        lat
        lng
      }
      vehicle {
        id
        name
        description
        image
        max_passenger
      }
      price
      round
    }
    total
  }
}
  `;

    const [selectedOption, setSelectedOption] = useState(null);
    const [transfers, setTransfers] = useState([]);
    const [allTransfers, setAllTransfers] = useState([]);
    useEffect(() => {
        console.log(selectedOption);
        const filtered = allTransfers.filter(transfer => transfer.origin.id === selectedOption.value);
        setTransfers(filtered);
    }, [selectedOption]);

    let limit = 120;
    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const SetData = (value) => {
        let allData = value.transfers.data;
        setAllTransfers(allData)
        //setTransfers(allTransfers)
    }

    const { loading, error, data } = useQuery(GET_TRANSFER, {
        variables: {
            "limit": limit,
            "offset": 0,
        }, onCompleted: SetData
    });


    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;

    const optionsForDestination = [
        { value: 4, label: "Donmuang Airport (DMK)" },
        { value: 5, label: "Suvarnabhumi Airport (BKK)" },
        { value: 28, label: "BANGKOK CITY" },
    ];

    return (
        <>
                <div className="row g-4">
                    <div className="col-lg-10">
                        <div className="row gx-0 gy-4">
                            <div className="col-lg-6 col-md-6">
                                <div className="select-box">
                                    <div className="searchbox-icons">
                                        <i className="bi bi-geo-alt" />
                                    </div>
                                    <div className="searchbox-input">
                                        <label htmlFor="activity-dropdown">From</label>
                                        <Select
                                            placeholder="Where will we have a pick-up you?"
                                            className="selectbox"
                                            onChange={setSelectedOption}
                                            defaultValue={selectedOption}
                                            options={optionsForDestination}
                                            touchUi={false}
                                            showGroupWheel={true}
                                        ></Select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <div className="row g-4 mt-3 ps-1 pe-1">
                { selectedOption != null ? transfers.map((transfer) => (
                    <div className="col-4">
                        <TransferCard
                            transfer={transfer}
                        />
                    </div>
                )) :  data.transfers?.data.map((transfer) => (
                    <div className="col-4">
                        <TransferCard
                            transfer={transfer}
                        />
                    </div>
                ))
                }
            </div>
        </>
    );
}

export default TransferList;
